const supplierTeamAdminRoutes = {
  path: "/supplier_team/admin",
  component: () => import("layouts/SupplierTeamAdminPortalLayout.vue"),
  children: [
    {
      path: "dashboard",
      name: "SupplierAdminDashboardPage",
      component: () =>
        import("pages/supplierTeam/SupplierAdminDashboardPage.vue"),
    },
    {
      path: "profile",
      name: "SupplierAdminProfile",
      component: () => import("pages/supplierTeam/SupplierAdminProfile.vue"),
    },
    {
      path: "sellers",
      name: "SupplierAdminSupplierList",
      component: () =>
        import("pages/supplierTeam/SupplierAdminSupplierList.vue"),
    },
    {
      path: "sellers/add",
      name: "SupplierAdminAddSeller",
      component: () => import("pages/supplierTeam/SupplierAdminAddSeller.vue"),
    },
    {
      path: "sellers/:sellerId",
      name: "SupplierAdminViewSeller",
      component: () => import("pages/supplierTeam/SupplierAdminViewSeller.vue"),
    },
    {
      path: "sellers/:sellerId/edit",
      name: "SupplierAdminEditSeller",
      component: () => import("pages/supplierTeam/SupplierAdminEditSeller.vue"),
    },
    {
      path: "master_orders/:masterOrderId",
      name: "SupplierAdminViewMasterOrder",
      component: () =>
        import("pages/supplierTeam/SupplierAdminViewMasterOrder.vue"),
    },
    {
      path: "master_orders",
      name: "SupplierAdminMasterOrdersPath",
      children: [
        {
          path: "",
          name: "SupplierAdminMasterOrdersSearch",
          component: () =>
            import("pages/supplierTeam/SupplierAdminMasterOrdersSearch.vue"),
        },
        {
          path: "qc/reports",
          name: "SupplierAdminQCReports",
          component: () =>
            import("pages/supplierTeam/SupplierAdminQCReports.vue"),
        },
        {
          path: "dispatch/calendar",
          name: "SupplierAdminOrderDispatchCalendar",
          component: () =>
            import("pages/supplierTeam/SupplierAdminOrderDispatchCalendar.vue"),
        },
      ],
    },
  ],
};

export default supplierTeamAdminRoutes;
