const adminRoutes = {
  path: "/admin/",
  component: () => import("layouts/AdminPortalLayout.vue"),
  children: [
    {
      path: "dashboard",
      name: "AdminDashboardPage",
      component: () => import("pages/admin/AdminDashboardPage.vue"),
    },
    {
      path: "profile",
      name: "AdminViewProfile",
      component: () => import("pages/admin/AdminProfile.vue"),
    },
    {
      path: "categories/manage",
      name: "AdminManageCategories",
      component: () => import("pages/admin/AdminManageCategories.vue"),
    },
    {
      path: "categories/:categoryId/edit",
      name: "AdminEditCategory",
      component: () => import("pages/admin/AdminManageCategory.vue"),
    },
    {
      path: "categories/:categoryId/download",
      name: "AdminDownloadCategory",
      component: () => import("pages/admin/AdminCategoryDownloadManager.vue"),
    },
    {
      path: "categories/:categoryId/fulfillment_workflow",
      name: "AdminEditFulfillmentWorkflow",
      component: () =>
        import("pages/admin/AdminCategoryFulfillmentWorkflowManager.vue"),
    },
    {
      path: "categories/:categoryId/conditions/create",
      name: "AdminCreateConditions",
      component: () => import("pages/admin/AdminCreateConditions.vue"),
    },
    {
      path: "categories/:categoryId/conditions/:conditionId/edit",
      name: "AdminEditConditions",
      component: () => import("pages/admin/AdminEditCondition.vue"),
    },
    {
      path: "sellers/add",
      name: "AdminAddSeller",
      component: () => import("pages/admin/AdminAddSeller.vue"),
    },
    {
      path: "sellers/manage",
      name: "AdminManageSellers",
      component: () => import("pages/admin/AdminManageSellers.vue"),
    },
    {
      path: "buyers/manage",
      name: "AdminManageBuyers",
      component: () => import("pages/admin/AdminManageBuyers.vue"),
    },
    {
      path: "buyers/:buyerId",
      name: "AdminViewBuyer",
      component: () => import("pages/admin/AdminViewBuyer.vue"),
    },
    {
      path: "buyers/:buyerId/edit",
      name: "AdminEditBuyer",
      component: () => import("pages/admin/AdminEditBuyer.vue"),
    },
    {
      path: "databases/manage",
      name: "AdminManageDatabases",
      component: () => import("pages/admin/AdminSheetDatabaseManager.vue"),
    },
    {
      path: "users/manage",
      name: "AdminManageUsers",
      component: () => import("pages/admin/AdminManageUsers.vue"),
    },
    {
      path: "categories/:categoryId/skus/compare",
      name: "AdminCompareSKUs",
      component: () => import("pages/admin/AdminCompareSKUs.vue"),
    },

    {
      path: "users/manage/password",
      name: "AdminSetPassword",
      component: () => import("pages/admin/AdminSetPasswordPage.vue"),
    },
    {
      path: "sellers/:sellerId",
      name: "AdminViewSeller",
      component: () => import("pages/admin/AdminViewSeller.vue"),
    },
    {
      path: "sellers/:sellerId/catalog",
      name: "AdminSellerCatalog",
      component: () => import("pages/admin/AdminSellerCatalog.vue"),
    },

    {
      path: "sellers/:sellerId/edit",
      name: "AdminEditSeller",
      component: () => import("pages/admin/AdminEditSeller.vue"),
    },
    {
      path: "sellers/:sellerId/categories/:categoryId/skus",
      name: "AdminViewSellerSKUs",
      component: () => import("pages/admin/AdminSellerSKUs.vue"),
    },
    {
      path: "sellers/:sellerId/categories/:categoryId/skus/:skuId",
      name: "AdminViewSellerSKU",
      component: () => import("pages/admin/AdminSellerSKUView.vue"),
    },
    {
      path: "skus",
      name: "AdminSKUs",
      children: [
        {
          path: "seller_skus/live",
          name: "AdminManageLiveSellerSKUs",
          component: () => import("pages/admin/AdminManageLiveSellerSKUs.vue"),
        },
        {
          path: "seller_skus/draft",
          name: "AdminManageSellerDraftSKUs",
          component: () => import("pages/admin/AdminManageSellerDraftSKUs.vue"),
        },
        {
          path: "buyer_skus",
          name: "AdminBuyerSKUs",

          children: [
            {
              path: "",
              name: "AdminViewBuyerSKUs",
              component: () => import("pages/admin/AdminViewBuyerSKUs.vue"),
            },
            {
              path: "create",
              name: "AdminCreateBuyerSKU",
              component: () => import("pages/admin/AdminCreateBuyerSKU.vue"),
            },
            {
              path: ":buyerSKUId/edit",
              name: "AdminEditBuyerSKU",
              component: () => import("pages/admin/AdminEditBuyerSKU.vue"),
            },
          ],
        },
        {
          path: "sample_skus",
          name: "AdminSampleSKUs",

          children: [
            {
              path: "",
              name: "AdminViewSampleSKUs",
              component: () => import("pages/admin/AdminViewSampleSKUs.vue"),
            },
            {
              path: "create",
              name: "AdminCreateSampleSKU",
              component: () => import("pages/admin/AdminCreateSampleSKU.vue"),
            },
            {
              path: ":sampleSKUId/edit",
              name: "AdminEditSampleSKU",
              component: () => import("pages/admin/AdminEditSampleSKU.vue"),
            },
          ],
        },
      ],
    },

    {
      path: "reports",
      name: "AdminReports",
      children: [
        {
          path: "",
          name: "AdminMasterOrderReport",
          component: () => import("pages/admin/AdminMasterOrderReport.vue"),
        },
        {
          path: "master_order",
          name: "AdminMasterOrderReport2",
          component: () => import("pages/admin/AdminMasterOrderReport.vue"),
        },
        {
          path: "qc",
          name: "AdminQCReports",
          component: () => import("pages/admin/AdminQCReports.vue"),
        },
        {
          path: "buyer_skus",
          name: "AdminBuyerReports",
          component: () => import("pages/admin/AdminBuyerReports.vue"),
        },
        {
          path: "buyer_skus/:buyerId/report",
          name: "AdminBuyerSKUSummaryReport",
          component: () => import("pages/admin/AdminBuyerSKUSummaryReport.vue"),
        },
      ],
    },
    {
      path: "master_orders",
      name: "AdminMasterOrders",
      children: [
        {
          path: "",
          name: "AdminSearchMasterOrders",
          component: () => import("pages/admin/AdminMasterOrdersSearch.vue"),
        },
        {
          path: "dispatch/calendar",
          name: "AdminDispatchCalendar",
          component: () => import("pages/admin/AdminDispatchCalendar.vue"),
        },
        {
          path: "qc/calendar",
          name: "AdminOrdersQCCalendar",
          component: () => import("pages/admin/AdminOrdersQCCalendar.vue"),
        },
      ],
    },
    {
      path: "master_orders/:masterOrderId",
      name: "AdminViewMasterOrderPage",
      component: () => import("pages/admin/AdminViewMasterOrder.vue"),
    },
    {
      path: "sample_orders",
      name: "AdminViewSampleOrders",
      component: () => import("pages/admin/AdminViewSampleOrders.vue"),
    },
    {
      path: "sample_orders/:sampleOrderId",
      children: [
        {
          path: "",
          name: "AdminViewSampleOrder",
          component: () => import("pages/admin/AdminViewSampleOrder.vue"),
        },
      ],
    },
    {
      path: "order_cart",
      name: "AdminCartPage",
      component: () => import("src/pages/admin/AdminCartPage.vue"),
    },
  ],
};
export default adminRoutes;
